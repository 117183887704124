export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0,2,4,6];

export const dictionary = {
		"/": [7],
		"/(landing)/creator-download": [9],
		"/(legal)/creator-launch-terms": [10,[3]],
		"/creators": [13],
		"/(app-subdomain)/direct/404": [8,[2]],
		"/download": [~14],
		"/gform/[form_id=notuuid]/[form_instance_id]/finish": [~16,[4]],
		"/gform/[form_id=notuuid]/[form_instance_id]/[step_id]": [~15,[4]],
		"/gform/[form_instance_id=uuid]/[...catchall]": [~17],
		"/notification-preferences/unsub/email/completed-all": [20,[5]],
		"/notification-preferences/unsub/email/[encrypted]/[iv]": [~18,[5]],
		"/notification-preferences/unsub/email/[encrypted]/[iv]/completed": [~19,[5]],
		"/(legal)/privacy": [11,[3]],
		"/roadmap": [~21],
		"/support/[[support_path]]": [~22,[6]],
		"/(legal)/terms": [12,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';